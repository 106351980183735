@import "../../colors";

@font-face {
  font-family: "digital-clock-font";
  src: url("./digital-7.ttf");
}

.mileage-numbers {
  font-family: "digital-clock-font";
  font-size: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.mileage-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}

.mileage-value {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 38px;
  text-align: center;
  background-color: $primary;
  color: $light;
  padding: 0 7px;
  margin: 4px 0;
}

.mileage-input {
  background-color: $light;
  font-size: 56px;
  width: 26px;
  height: 26px;
  color: #420707;
  border: 1px solid black;
  border-radius: 50%;
  // vertical text centering
  margin: auto;
  line-height: 1.25rem;
  // horizontal text centering
  padding: 0px;

  &:hover {
    cursor: pointer;
  }
}
